import { CarModel, Tenant } from '../general/types'

export enum CarShopType {
    OCCASIONS,
    INVENTORIES,
}

export interface ShopOfferType {
    name: string
    url: string
}

export interface ShopOfferMatrixPrice {
    id: string
    mileage: number
    duration: number
    priceIncVAT: number
    priceExVAT: number
}

export interface ShopOfferFOL {
    __typename: 'CarShop_ShopOfferFOL'
    id: string
    prices: ShopOfferMatrixPrice[]
}

export interface ShopOfferPrivateLease {
    __typename: 'CarShop_ShopOfferPrivateLease'
    id: string
    prices: ShopOfferMatrixPrice[]
}

export interface ShopOfferPurchase {
    __typename: 'CarShop_ShopOfferPurchase'
    id: string
    priceExVAT: number
    priceIncVAT: number
}

export interface ShopOfferReady2Share {
    __typename: 'CarShop_ShopOfferReady2Share'
    id: string
    prices: [
        {
            id: string
            businessMileage: number
            privateMileage: number
            businessPrice: number
            privatePrice: number
        }
    ]
}

export enum ShopOfferEnum {
    fol = 'CarShop_ShopOfferFOL',
    privateLease = 'CarShop_ShopOfferPrivateLease',
    purchase = 'CarShop_ShopOfferPurchase',
    ready2Share = 'CarShop_ShopOfferReady2Share',
}

// export type ShopOffer = ShopOfferPurchase['__typename'] | ShopOfferFOL['__typename'] | ShopOfferPrivateLease['__typename'] | ShopOfferReady2Share['__typename']

export interface ShopCar {
    id: string
    licensePlate: string
    previewImageUrl: string
    mainImageUrl: string
    exterior: string[]
    interior: string[]
    accessories: string[]
    exteriorImageUrl: string
    interiorImageUrl: string
    featuresImageUrl: string
    availabilityDate: string
    availability: string
    superCharging: string
    simpleColor: string
    color: string
    contractTakeover: boolean
    contractEndDate: string
    currentMileage: number
    fiscalValueIncVAT: number
    firstRegistrationDate: string
    condition: 'INVENTORY' | 'OCCASION'
    banner?: {
        id: string
        label: string
    }
    brand: {
        id: string
        name: string
    }
    model: {
        id: string
        name: string
    }
    edition: {
        id: string
        name: string
    }
    offers: [ShopOfferFOL | ShopOfferPrivateLease | ShopOfferPurchase | ShopOfferReady2Share]
}

export interface CarShopLabels {
    availabilityLabel: string
    availabilityStateAtXDate: string
    availabilityStateAvailableContractTakeovers: string
    availabilityStateNotAvailable: string
    availabilityStateNowAvailable: string
    availabilityStateReserved: string
    backToOverviewLinkText: string
    checkoutButtonAccompanyingText: string
    checkoutButtonText: string
    colorDefaultValue: string
    colorLabel: string
    durationInMonthsText: string
    excludingTax: string
    excludingTaxAbbreviation: string
    fiscalValueLabel: string
    includingTax: string
    includingTaxAbbreviation: string
    kilometersAbbreviation: string
    licensePlateLabel: string
    mileageLabel: string
    mileageLabelOptionalDisclaimer: string | undefined
    modelDefaultValue: string
    modelLabel: string
    noCarsMatchTheseFiltersText: string
    noCarsMatchTheseFiltersTextR2S: string
    noCarsMatchTheseFiltersTextR2SLink: string
    offerTypeFolName: string
    offerTypeLabel: string
    offerTypePrivateLeaseName: string
    offerTypePurchaseName: string
    offerTypeReady2ShareName: string
    perMonthAbbreviation: string
    priceTableBusinessKilometersLabel: string
    priceTableBusinessPriceLabel: string
    priceTableButtonAccompanyingText: string
    priceTableButtonText: string
    priceTableButtonUrl: string
    priceTableContractTakeoverMoreInformation: string
    priceTableContractTakeoverText: string
    priceTableFinancingButtonText: string
    priceTableOfferTypeLabel: string
    priceTablePrivateKilometersLabel: string
    priceTablePrivatePriceLabel: string
    priceTablePurchaseText: string
    priceTableTitle: string
    redirectedFromUnknownIdBanner: string
    registrationDateLabel: string
    sortingOptionAvailableFromAscending: string
    sortingOptionAvailableFromDescending: string
    sortingOptionPriceAscending: string
    sortingOptionPriceDescending: string
    sortingOptionsLabel: string
}

export type RedrivePricePerKm = {
    [key in CarModel]: { [ShopOfferEnum.fol]: number; [ShopOfferEnum.privateLease]?: number }
}
export type RedrivePricingRow = {
    title: string
    edition?: string
    condition?: RedriveCarCondition
    fromPrice: { [ShopOfferEnum.fol]: number; [ShopOfferEnum.privateLease]?: number }
    withAsterix?: boolean
    noPriceMessage?: string
}

export type RedrivePricingTable = {
    [key in Tenant]?: {
        [key in CarModel]?: RedrivePricingRow[]
    }
}

export enum RedriveCarCondition {
    AS_NEW = 'as_new',
    USED = 'used',
    HEAVILY_USED = 'heavily_used',
}
